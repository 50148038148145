// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-advanced-instructions-js": () => import("./../../../src/pages/advanced-instructions.js" /* webpackChunkName: "component---src-pages-advanced-instructions-js" */),
  "component---src-pages-best-bronze-ball-players-js": () => import("./../../../src/pages/best-bronze-ball-players.js" /* webpackChunkName: "component---src-pages-best-bronze-ball-players-js" */),
  "component---src-pages-best-gold-ball-players-js": () => import("./../../../src/pages/best-gold-ball-players.js" /* webpackChunkName: "component---src-pages-best-gold-ball-players-js" */),
  "component---src-pages-best-managers-2020-js": () => import("./../../../src/pages/best-managers-2020.js" /* webpackChunkName: "component---src-pages-best-managers-2020-js" */),
  "component---src-pages-best-managers-js": () => import("./../../../src/pages/best-managers.js" /* webpackChunkName: "component---src-pages-best-managers-js" */),
  "component---src-pages-best-players-2020-js": () => import("./../../../src/pages/best-players-2020.js" /* webpackChunkName: "component---src-pages-best-players-2020-js" */),
  "component---src-pages-best-players-ball-type-js": () => import("./../../../src/pages/best-players-ball-type.js" /* webpackChunkName: "component---src-pages-best-players-ball-type-js" */),
  "component---src-pages-best-silver-ball-players-js": () => import("./../../../src/pages/best-silver-ball-players.js" /* webpackChunkName: "component---src-pages-best-silver-ball-players-js" */),
  "component---src-pages-coins-gp-js": () => import("./../../../src/pages/coins-gp.js" /* webpackChunkName: "component---src-pages-coins-gp-js" */),
  "component---src-pages-coming-from-fifa-js": () => import("./../../../src/pages/coming-from-fifa.js" /* webpackChunkName: "component---src-pages-coming-from-fifa-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contract-stamina-renewal-js": () => import("./../../../src/pages/contract-stamina-renewal.js" /* webpackChunkName: "component---src-pages-contract-stamina-renewal-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-first-steps-js": () => import("./../../../src/pages/first-steps.js" /* webpackChunkName: "component---src-pages-first-steps-js" */),
  "component---src-pages-game-modes-challenges-js": () => import("./../../../src/pages/game-modes-challenges.js" /* webpackChunkName: "component---src-pages-game-modes-challenges-js" */),
  "component---src-pages-gameplay-tips-js": () => import("./../../../src/pages/gameplay-tips.js" /* webpackChunkName: "component---src-pages-gameplay-tips-js" */),
  "component---src-pages-how-to-get-more-gp-js": () => import("./../../../src/pages/how-to-get-more-gp.js" /* webpackChunkName: "component---src-pages-how-to-get-more-gp-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legends-js": () => import("./../../../src/pages/legends.js" /* webpackChunkName: "component---src-pages-legends-js" */),
  "component---src-pages-manager-2021-js": () => import("./../../../src/pages/manager2021.js" /* webpackChunkName: "component---src-pages-manager-2021-js" */),
  "component---src-pages-manager-js": () => import("./../../../src/pages/manager.js" /* webpackChunkName: "component---src-pages-manager-js" */),
  "component---src-pages-manager-upgrades-js": () => import("./../../../src/pages/manager-upgrades.js" /* webpackChunkName: "component---src-pages-manager-upgrades-js" */),
  "component---src-pages-managers-js": () => import("./../../../src/pages/managers.js" /* webpackChunkName: "component---src-pages-managers-js" */),
  "component---src-pages-myclub-tips-tricks-js": () => import("./../../../src/pages/myclub-tips-tricks.js" /* webpackChunkName: "component---src-pages-myclub-tips-tricks-js" */),
  "component---src-pages-player-attributes-js": () => import("./../../../src/pages/player-attributes.js" /* webpackChunkName: "component---src-pages-player-attributes-js" */),
  "component---src-pages-player-form-condition-rating-js": () => import("./../../../src/pages/player-form-condition-rating.js" /* webpackChunkName: "component---src-pages-player-form-condition-rating-js" */),
  "component---src-pages-player-skills-js": () => import("./../../../src/pages/player-skills.js" /* webpackChunkName: "component---src-pages-player-skills-js" */),
  "component---src-pages-playing-styles-js": () => import("./../../../src/pages/playing-styles.js" /* webpackChunkName: "component---src-pages-playing-styles-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-signing-managers-js": () => import("./../../../src/pages/signing-managers.js" /* webpackChunkName: "component---src-pages-signing-managers-js" */),
  "component---src-pages-signing-new-players-agents-js": () => import("./../../../src/pages/signing-new-players-agents.js" /* webpackChunkName: "component---src-pages-signing-new-players-agents-js" */),
  "component---src-pages-signing-new-players-scout-combinations-js": () => import("./../../../src/pages/signing-new-players-scout-combinations.js" /* webpackChunkName: "component---src-pages-signing-new-players-scout-combinations-js" */),
  "component---src-pages-tip-js": () => import("./../../../src/pages/tip.js" /* webpackChunkName: "component---src-pages-tip-js" */),
  "component---src-pages-trading-players-js": () => import("./../../../src/pages/trading-players.js" /* webpackChunkName: "component---src-pages-trading-players-js" */),
  "component---src-pages-training-players-js": () => import("./../../../src/pages/training-players.js" /* webpackChunkName: "component---src-pages-training-players-js" */)
}

